<template>
  <div id="b2c" class="main-body">
    <div id="nav-white" />
    <div id="services-front" class="international-bg">
      <h1 class="zoomIn">International Trading</h1>
    </div>

    <div id="content">
      <h1 class="fadeIn">Brief Summary</h1>
      <p class="fadeIn">
        International Trading can also be understood as the global exchange of
        goods, services, and capital between countries. It allows nations to buy
        and sell products that may be unavailable or costlier domestically,
        fostering a worldwide network of economic interdependence.
      </p>
    </div>
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";

export default {
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.5);
    this.setupIntersectionObserver(".zoomIn", 0.1);
  },
};
</script>
